
export const menuItems = [
   
    {
        id: 1,
        label: 'Marketplace',
       // icon: 'fa fa-home',
        to:{ name: 'home'},
        routes:[
            'home'
        ]
    },
    {
        id: 5000,
        label: 'Performance Overview',
       // icon: 'fa fa-coins',
       // role: ['administrator'],
        routes:[
            'performance-overview'
        ],
        to: { name: 'performance-overview'}
    },
    {
        id: 5500,
        label: 'Execution',
       // icon: 'fa fa-coins',
       // role: ['administrator'],
        routes:[
            'execution'
        ],
        to: { name: 'execution'}
    },
    // {
    //     id: 4000,
    //     label: 'pages.products.menuItem-title',
    //    // icon: 'fa fa-briefcase',
    //     role: ['administrator'],
    //     to:{ name: 'products'}
    // },
    {
        id: 3500,
        label: 'pages.fx-hedge.menuItem-title',
       // icon: 'fa fa-briefcase',
        role: ['fx_hedge'],
        to:{ name: 'fx-hedge'},
        routes:[
            'fx-hedge',
            'fx-hedge-details'
        ],
    },
    // {
    //     id: 3000,
    //     label: 'pages.instruments.menuItem-title',
    //    // icon: 'fa fa-capsules',
    //     role: ['administrator'],
    //     routes:[
    //         'stocks', 'futures', 'options', 'fx-rates'
    //     ],
    //     subItems: [
    //         {
    //             id: 3001,
    //             label: 'pages.instruments.options',
    //             role: ['administrator'],
    //             to:{ name: 'options' }
    //         },
    //         {
    //             id: 3002,
    //             label: 'pages.instruments.stocks',
    //             role: ['administrator'],
    //             to:{ name: 'stocks' }
    //         },
    //         {
    //             id: 3003,
    //             label: 'pages.instruments.futures',
    //             role: ['administrator'],
    //             to:{ name: 'futures' }
    //         },
    //         {
    //             id: 3004,
    //             label: 'pages.instruments.fxRates',
    //             role: ['administrator'],
    //             to:{ name: 'fx-rates' }
    //         }
    //     ]
    // },
    {
        id: 2000,
        label: 'pages.dictionaries.menuItem-title',
       // icon: 'fa fa-list',
        role: ['administrator'],
        to:{ name: 'dictionaries'}
    },
    {
        id: 1000,
        label: 'pages.administration.menuItem-title',
      //  icon: 'fa fa-users',
        role: ['administrator'],
        routes:[
            'users'
        ],
        subItems: [
            {
                id: 2001,
                label: 'pages.administration.users.menuItem-title',
                role: ['administrator'],
                to:{ name: 'users' }
              
            },
            {
                id: 2002,
                label: 'Demo Requests',
                role: ['administrator'],
                to:{ name: 'demo-requests' }
              
            },
            {
                id: 2003,
                label: 'News Subscription',
                role: ['administrator'],
                to:{ name: 'news-subscription' }
              
            },
            {
                id: 2004,
                label: 'Visitor Messages',
                role: ['administrator'],
                to:{ name: 'visitor-messages' }
              
            }
        ]
    },
    {
        id: 3000,
        label: 'Resources',
      //  icon: 'fa fa-users',
      
        routes:[
            'tutorial'
        ],
        subItems: [
            {
                id: 3500,
                label: 'pages.tutorial.menuItem-title',
             //   icon: 'fas fa-book-reader',
                to:{ name: 'tutorial'},
                routes:[
                    'tutorial'
                ]
            },
            {
                id: 3600,
                label: 'Knowledge Hub',
             //   icon: 'fas fa-book-reader',
                to:'https://knowledgebase.c8-studio.com/en/c8-knowledge-hub',
                external: true,
                routes:[
                    'tutorial'
                ]
            },
            {
                id: 3700,
                label: 'News',
             //   icon: 'fas fa-book-reader',
                to:'https://c8-technologies.com/#news-and-update',
                external: true,
                routes:[
                    'tutorial'
                ]
            },
            
        ]
    },
    
]