export function parseErrors (error) {
  
    let a = [];
    const errors =  (error.response.data || {}).errors || error.response.data;
    for (var key in errors) {
        for (var e in errors[key]) {
            a.push(errors[key][e])
        }
    }
    return a;
}

export const assetClasses = 
{
    bonds: 10,
    commodity: 20,
    cryptoCurrency: 30,
    etf: 40,
    equities: 50,
    fx: 60,
    fixedIncome: 70,
    futures: 80,
    futuresCommodities: 90,
    futuresRates: 100,
    moneyMarket: 110
}